import Img from 'gatsby-image';
import React from 'react'
import { Trans } from 'gatsby-plugin-react-i18next'

export default function Gallery({images}) {
    return (
        <section id="mainGallery" className="py-5">
            
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <h3><Trans>Someworks</Trans></h3>
                    </div>
                </div>
            </div>

            <div className="container-gallery">
                { images.map(image => (
                <figure key={image.node.id}>
                <Img
                    fluid={image.node.childImageSharp.fluid}
                    className="img-fluid" />
                </figure>
                ))}
            </div>

        </section>
    );
};